<template>
  <div class="container">
    <a-button
      @click="() => $router.back()"
      style="border-radius: 20px; background-image: linear-gradient(to right, #1D2671 , #830051); color: white;"
      ><LeftOutlined /> Back</a-button
    >

    <div
      style="position: relative; width:100%; height: 100vh; display: flex; align-items: center; justify-content: center; "
    >
      <div
        class="img-wrapper"
        style="width: 100%; height: 100%; overflow-y: scroll; position: absolute;   scroll-snap-type: y mandatory; display: flex; flex-direction: column; align-items: center;"
      >
        <section
          style="width: 100%; height: 100%; display: flex; justify-content: center; position: relative;"
          v-for="video in videos"
          :key="video.id"
          class="my-3"
        >
          <div
            style="position: absolute;width: 100%; background-color: rgba(112, 100, 100, 0.298); border-radius: 20px;"
          >
            <div class="w-100 h-100 d-flex align-items-baseline justify-content-between pt-4 px-4">
              <p class="" style="width: 60%; font-size: 14px;color:white; z-index: 2;">
                {{ video.title }}
              </p>
              <div class="" style="font-size: 14px;color:white">
                <EyeOutlined /> {{ video.totalViews }}
              </div>
            </div>
          </div>
          <div
            style="position: absolute;top: 20px; display: flex;align-items: center;justify-content: space-between; width: 100%;"
          ></div>

          <video
            id="myVideo"
            ref="videoPlayer"
            :src="baseUrl + video.url"
            class="w-100 h-100"
            @click="handleVideo()"
            controlsList="nofullscreen"
            style="border-radius: 20px;"
            controls
          ></video>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import { getVideo } from '../../services/axios/pulih'
import { useRouter } from 'vue-router'
import {
  PlusOutlined,
  LockOutlined,
  UploadOutlined,
  EyeOutlined,
  SettingOutlined,
  DeleteOutlined,
  ExclamationCircleOutlined,
  UnlockOutlined,
  EditOutlined,
  LeftOutlined,
} from '@ant-design/icons-vue'

import {
  onMounted,
  onBeforeUnmount,
  ref,
  reactive,
  createVNode,
  toRef,
  toRefs,
  watch,
  computed,
} from 'vue'
export default {
  components: { EyeOutlined, LeftOutlined },
  setup() {
    const videoPlayer = ref(null) // Referensi untuk video player
    let vid = document.getElementById('myVideo')
    // Metode untuk memainkan video
    let play = ref(true)
    const videos = ref([
      { id: 1, src: '/video/video-1.mp4' },
      { id: 2, src: '/video/video-2.mp4' },
      { id: 3, src: '/video/video-3.mp4' },
    ])
    const baseUrl = ref('https://pulih-api-prod.digitala.id')
    const router = useRouter()
    const fetchData = async () => {
      const res = await getVideo()
      console.log(res, 'inivideo')
      videos.value = res
    }
    onMounted(() => {
      window.scrollTo(0, 0)
      fetchData()
    })
    const handleVideo = () => {
      if (play.value) {
        pauseVid()
      } else {
        playVid()
      }
    }

    function playVid() {
      console.log('inplay')
      vid.play()
    }

    function pauseVid() {
      console.log('inipause')
      vid.pause()
    }

    return { videoPlayer, playVid, pauseVid, handleVideo, videos, baseUrl }
  },
}
</script>

<style>
.img-wrapper section {
  scroll-snap-align: start;
  height: 100%;
}
.img-wrapper {
  overflow-y: scroll;
  scrollbar-width: none; /* For Firefox */
  -ms-overflow-style: none; /* For Internet Explorer and Edge */
}

.img-wrapper::-webkit-scrollbar {
  display: none; /* For Chrome, Safari, and Opera */
  background-color: rgba(112, 100, 100, 0.098);
}
.img-wrapper section {
  scroll-snap-align: start;
  max-height: 700px;
  width: 100%;
  max-width: 375px;
}

@media (max-width: 768px) {
  .img-wrapper section {
    width: 100vh; /* Set width based on the viewport height for mobile screens */
  }
}
</style>
