<template>
  <div class="container">
    <Banner />
    <div class="card w-100" style="border-radius: 24px;">
      <div class="row" :class="$style['title-step']">
        <div class="col-12 col-xl-3 mb-3 mb-xl-0 pr-xl-0">
          <div
            class="card w-100 h-100 d-flex flex-column align-items-center justify-content-center"
            style="border-radius: 24px; background-color: #3D1053;"
          >
            <h2 class="text-white" :class="$style['step-title']"><strong>Status Pulih</strong></h2>
          </div>
        </div>
        <div
          class="col-12 col-xl-9 d-flex flex-column align-items-center justify-content-center mb-4 mb-xl-0"
        >
          <div class="d-flex w-100 px-5 align-items-center justify-content-center">
            <div
              :class="$style['step']"
              :style="{
                width:
                  isAurora && settings.isMobileView
                    ? '25px !important'
                    : isAurora && settings.isTabletView
                    ? '60px !important'
                    : '',
              }"
            >
              <img
                src="../../assets/img/checklis.png"
                alt=""
                :class="$style['img-step']"
                v-if="!steps.persetujuan"
              />
              <img src="../../assets/img/checked.png" alt="" :class="$style['img-step']" v-else />
              <p class="m-0 mt-3 text-center">Persetujuan Pasien</p>
            </div>
            <div class="d-flex align-items-center">
              <div
                :class="$style['line']"
                :style="{
                  width:
                    isAurora && settings.isMobileView
                      ? '25px !important'
                      : isAurora && settings.isTabletView
                      ? '60px !important'
                      : '',
                }"
              ></div>
            </div>
            <div
              :class="$style['step']"
              :style="{
                width:
                  isAurora && settings.isMobileView
                    ? '25px !important'
                    : isAurora && settings.isTabletView
                    ? '60px !important'
                    : '',
              }"
            >
              <img
                src="../../assets/img/checklis.png"
                alt=""
                :class="$style['img-step']"
                v-if="!steps.tinjauHasil"
              />
              <img src="../../assets/img/checked.png" alt="" :class="$style['img-step']" v-else />
              <p class="m-0 mt-3 text-center">
                Tinjau <br />
                Hasil
              </p>
            </div>
            <!-- BATES -->
            <div class="d-flex align-items-center" v-if="isAurora">
              <div
                :class="$style['line']"
                :style="{
                  width:
                    isAurora && settings.isMobileView
                      ? '25px !important'
                      : isAurora && settings.isTabletView
                      ? '60px !important'
                      : '',
                }"
              ></div>
            </div>
            <div
              :class="$style['step']"
              :style="{
                width:
                  isAurora && settings.isMobileView
                    ? '25px !important'
                    : isAurora && settings.isTabletView
                    ? '60px !important'
                    : '',
              }"
              v-if="isAurora"
            >
              <img
                src="../../assets/img/checklis.png"
                alt=""
                :class="$style['img-step']"
                v-if="!steps.unggahKwitansi"
              />
              <img src="../../assets/img/checked.png" alt="" :class="$style['img-step']" v-else />
              <p class="m-0 mt-3 text-center">
                Unggah <br />
                Asuransi
              </p>
            </div>
            <!-- BATES/ -->
            <div class="d-flex align-items-center">
              <div
                :class="$style['line']"
                :style="{
                  width:
                    isAurora && settings.isMobileView
                      ? '25px !important'
                      : isAurora && settings.isTabletView
                      ? '60px !important'
                      : '',
                }"
              ></div>
            </div>
            <div
              :class="$style['step']"
              :style="{
                width:
                  isAurora && settings.isMobileView
                    ? '25px !important'
                    : isAurora && settings.isTabletView
                    ? '60px !important'
                    : '',
              }"
            >
              <img
                src="../../assets/img/checklis.png"
                alt=""
                :class="$style['img-step']"
                v-if="!steps.konfirmasiDokter"
              />
              <img src="../../assets/img/checked.png" alt="" :class="$style['img-step']" v-else />
              <p class="m-0 mt-3 text-center">Konfirmasi Dokter</p>
            </div>
            <div class="d-flex align-items-center">
              <div
                :class="$style['line']"
                :style="{
                  width:
                    isAurora && settings.isMobileView
                      ? '25px !important'
                      : isAurora && settings.isTabletView
                      ? '60px !important'
                      : '',
                }"
              ></div>
            </div>
            <div
              :class="$style['step']"
              :style="{
                width:
                  isAurora && settings.isMobileView
                    ? '25px !important'
                    : isAurora && settings.isTabletView
                    ? '60px !important'
                    : '',
              }"
            >
              <img
                src="../../assets/img/checklis.png"
                alt=""
                :class="$style['img-step']"
                v-if="!steps.unggahResep"
              />
              <img src="../../assets/img/checked.png" alt="" :class="$style['img-step']" v-else />
              <p class="m-0 mt-3 text-center">Unggah Resep</p>
            </div>
            <div class="d-flex align-items-center">
              <div
                :class="$style['line']"
                :style="{
                  width:
                    isAurora && settings.isMobileView
                      ? '25px !important'
                      : isAurora && settings.isTabletView
                      ? '60px !important'
                      : '',
                }"
              ></div>
            </div>
            <div
              :class="$style['step']"
              :style="{
                width:
                  isAurora && settings.isMobileView
                    ? '25px !important'
                    : isAurora && settings.isTabletView
                    ? '60px !important'
                    : '',
              }"
            >
              <img
                src="../../assets/img/checklis.png"
                alt=""
                :class="$style['img-step']"
                v-if="!steps.verifikasi"
              />
              <img src="../../assets/img/checked.png" alt="" :class="$style['img-step']" v-else />
              <p class="m-0 mt-3 text-center">Verifikasi Pasien</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="w-100 mt-5 mb-3">
      <div class="d-flex flex-row justify-content-between align-items-baseline">
        <h5 :class="$style['title-section']"><strong>Menu Utama</strong></h5>
        <h5 :class="$style['title-section']">
          <strong>{{ dataPatient.code }}</strong>
        </h5>
      </div>
    </div>
    <!-- Menu Utama -->
    <div class="row p-3">
      <div
        class="col-4  px-2 px-sm-2  d-flex flex-column align-items-center justify-content-center"
      >
        <div class="w-100 h-100 p-xl-5">
          <img
            src="../../assets/img/test-laboratorium.png"
            :class="$style['main-menu-item']"
            alt=""
            style=""
          />
        </div>
        <p class="text-center my-md-4 mt-xl-0">
          <strong :class="$style['menu-title']">Tes Laboratorium</strong>
        </p>
      </div>
      <div
        class="col-4  px-2 px-sm-2  d-flex flex-column align-items-center justify-content-center"
      >
        <div class="w-100 h-100 p-xl-5">
          <img
            src="../../assets/img/program-pulih.png"
            :class="$style['main-menu-item']"
            @click="handleMenu('program-pulih')"
            v-if="step < 5"
          />
          <img
            src="../../assets/img/disabled_program_pulih.png"
            :class="$style['main-menu-item']"
            v-else
          />
        </div>
        <p class="text-center my-md-4 mt-xl-0">
          <strong :class="$style['menu-title']">Program PAP</strong>
        </p>
      </div>
      <div
        class="col-4  px-2 px-sm-2  d-flex flex-column align-items-center justify-content-center"
      >
        <div class="w-100 h-100 p-xl-5">
          <img
            src="../../assets/img/profil.png"
            :class="$style['main-menu-item']"
            @click="handleMenu('profil')"
          />
        </div>
        <p class="text-center my-md-4 mt-xl-0">
          <strong :class="$style['menu-title']">Profil</strong>
        </p>
      </div>
      <div
        class="col-4  px-2 px-sm-2  d-flex flex-column align-items-center justify-content-center"
      >
        <div class="w-100 h-100 p-xl-5">
          <img
            src="../../assets/img/artikel.png"
            :class="$style['main-menu-item']"
            @click="handleArticle"
          />
        </div>
        <p class="text-center my-md-4 mt-xl-0">
          <strong :class="$style['menu-title']">Artikel</strong>
        </p>
      </div>
      <div
        class="col-4  px-2 px-sm-2  d-flex flex-column align-items-center justify-content-center"
      >
        <div class="w-100 h-100 p-xl-5">
          <img
            src="../../assets/img/video.png"
            :class="$style['main-menu-item']"
            @click="handleVideo"
          />
        </div>
        <p class="text-center my-md-4 mt-xl-0">
          <strong :class="$style['menu-title']">Vidio</strong>
        </p>
      </div>
      <div
        class="col-4  px-2 px-sm-2  d-flex flex-column align-items-center justify-content-center"
      >
        <div class="w-100 h-100 p-xl-5">
          <img
            src="../../assets/img/bantuan.png"
            :class="$style['main-menu-item']"
            @click="handleMenu('bantuan')"
          />
        </div>
        <p class="text-center my-md-4 mt-xl-0">
          <strong :class="$style['menu-title']">Bantuan</strong>
        </p>
      </div>
    </div>

    <div class="w-100 mt-5 mb-3">
      <div class="d-flex flex-row justify-content-between align-items-baseline">
        <h5 :class="$style['title-section']"><strong>Artikel Untukmu</strong></h5>
        <p style="cursor: pointer;" @click="handleArticle">Lihat Semua</p>
      </div>
    </div>
    <div class=" mt-4">
      <div class="row flex-nowrap overflow-auto">
        <div
          v-for="article in articles"
          :key="article.id"
          class="col-auto"
          style="min-width: 250px; scroll-snap-type: x mandatory; scroll-behavior: smooth;"
        >
          <div class="card" :class="$style['custom-card']">
            <img
              :src="backendUrl + article.image"
              class="card-img-top"
              alt="Olahraga"
              style="border-radius: 12px; object-fit: cover"
            />

            <!-- <div :class="$style['overlay']"></div> -->
            <div class="px-0 px-sm-5">
              <h5 :class="$style['card-description']" style="color: #831A53">
                {{ formatDate(article.createdAt) }}
              </h5>
              <h5 :class="$style['card-title']">{{ truncateTitle(article.title) }}</h5>
              <h5 :class="$style['card-description']">{{ truncateDescription(article.body) }}</h5>
              <a-button
                type="primary"
                :class="$style['custom-button']"
                @click="handleArticleDetail(article.id)"
              >
                <img
                  src="../../assets/img/baca.png"
                  alt=""
                  style="width: 24px;height: 20px;margin-right: 10px;"
                />
                <p style="font-size: 24px">Baca</p></a-button
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="w-100 mt-5">
      <div
        class="d-flex flex-row justify-content-between align-items-center align-items-sm-baseline"
      >
        <h5 :class="$style['title-section']"><strong>Video Artikel</strong></h5>
        <p class="text-right" style="cursor: pointer;" @click="handleVideo">Lihat Semua</p>
      </div>
    </div>
    <!-- VIDEO ARTIKEL -->
    <div class="row m-0 mt-3">
      <div class="col-4 px-0 px-1" v-for="(video, idx) in videos" :key="idx">
        <div
          class="card"
          :class="$style['overlay-container']"
          style="height: auto; border-radius: 20px;"
        >
          <div
            style="width: 60px;height: 30px; border-radius: 8px; background-color: black; position: absolute; top: 20px; left: 20px;display: flex;align-items: center;justify-content: center;z-index: 9999999999;"
          >
            <EyeOutlined :style="{ fontSize: '24px', color: 'white' }" />
            <span class="text-white ml-2">{{ video.totalViews }}</span>
          </div>
          <div
            style="border-radius: 8px;  position: absolute; bottom: 50px; left: 20px;display: flex;align-items: center;justify-content: center;z-index: 9999999999;"
          >
            <span class="text-white " style="font-size: 20px;">{{ video.title }}</span>
          </div>
          <img
            :src="backendUrl + video.thumbnail"
            alt=""
            :class="$style['overlay-image']"
            style="border-radius: 20px;"
          />
          <div :class="$style['overlay']"></div>
        </div>
      </div>
    </div>
    <div class="w-100 mt-4" v-if="isProgramExist">
      <div
        class="d-flex flex-row justify-content-between align-items-center align-items-sm-baseline"
      >
        <h5 :class="$style['title-section']"><strong>Konsumsi Obat</strong></h5>
        <p class="text-right" @click="handleDrugHistory" v-if="isDrugReminderCreated">
          Riwayat Minum Obat
        </p>
        <p class="text-right" v-else>
          Riwayat Minum Obat
        </p>
      </div>
    </div>
    <!-- Daily Check Drugs -->
    <div class="pb-5 w-100 " v-if="isProgramExist">
      <div class="row w-100 m-0" :class="$style['konsumsi-section']" v-if="!isDrugReminderCreated">
        <div
          class="col-4 d-flex flex-column align-items-center justify-content-center"
          style="background: linear-gradient(to right, #351749, #FE5C7C) !important;border-radius: 20px 0 0 20px;"
        >
          <img src="../../assets/img/layer_4.png" alt="" :class="$style['img-clock-empty']" />
        </div>
        <div
          class="col-8 d-flex  align-items-center justify-content-between"
          :class="$style['konsumsi-kanan']"
        >
          <div class="d-flex align-items-center justify-content-center">
            <img src="../../assets/img/empty-reminder.png" alt="" :class="$style['img-obat']" />
            <div class="ml-5">
              <div
                class=""
                :class="$style['no-schedule']"
                style="height: 20px;background-color: #FE5C7C; border-radius: 20px;"
              ></div>
              <div
                class=" mt-2"
                :class="$style['no-schedule']"
                style="height: 20px;background-color: #FE5C7C; border-radius: 20px;"
              ></div>
            </div>
          </div>
        </div>
        <div class="text-center w-100 py-5">
          <p>
            Maaf, belum ada jadwal minum obat untukmu. Silahkan atur di pengingat minum obat.
          </p>
        </div>
      </div>
      <div :class="$style['carousel-container']" v-else>
        <div :class="$style['carousel-slide']" v-for="(jadwal, idx) in drugReminders" :key="idx">
          <div class="row mx-1" :class="$style['konsumsi-section']">
            <div
              class="col-4 d-flex flex-column align-items-center justify-content-center"
              style="background: linear-gradient(to right, #54BB9D, #28668B) !important; border-radius: 20px 0 0 20px;"
            >
              <img src="../../assets/img/jam.png" alt="" :class="$style['img-clock']" />
            </div>
            <div
              class="col-8 d-flex align-items-center justify-content-between"
              :class="$style['konsumsi-kanan']"
            >
              <div class="d-flex align-items-center justify-content-center">
                <img
                  src="../../assets/img/pil.png"
                  alt=""
                  :class="$style['img-obat']"
                  v-if="
                    dataPatient.programs[0].programType.name === 'TAGRISSO' ||
                      dataPatient.programs[0].programType.name === 'CALQUENCE'
                  "
                />
                <img
                  src="../../assets/img/jarum_suntik.png"
                  alt=""
                  :class="$style['img-obat']"
                  v-if="
                    dataPatient.programs[0].programType.name === 'FASLODEX' ||
                      dataPatient.programs[0].programType.name === 'IMFINZI' ||
                      dataPatient.programs[0].programType.name === 'IMJUDO'
                  "
                />
                <img
                  src="../../assets/img/kapsul.png"
                  alt=""
                  :class="$style['img-obat']"
                  v-if="dataPatient.programs[0].programType.name === 'LYNPARZA'"
                />
                <div class="ml-4">
                  <p class="m-0" :class="$style['text-obat']">
                    <strong>{{ dataPatient.programs[0].programType.name }}</strong>
                  </p>
                  <p class="m-0" :class="$style['text-obat']">
                    <strong>{{ jadwal.jam }}</strong>
                  </p>
                </div>
              </div>
              <div>
                <a-checkbox
                  v-model:checked="jadwal.checked"
                  @click="
                    toggleChecked(
                      jadwal.jam,
                      dataPatient.id,
                      dataPatient.programs[0].programType.name,
                      cardIndex,
                    )
                  "
                ></a-checkbox>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- <div
        class="row"
        :class="$style['konsumsi-section']"
        v-else
        v-for="(jadwal, idx) in drugReminders"
        :key="idx"
      >
        <div
          class="col-4 d-flex flex-column align-items-center justify-content-center"
          style="background: linear-gradient(to right, #54BB9D, #28668B) !important;border-radius: 20px 0 0 20px;"
        >
          <img src="../../assets/img/jam.png" alt="" :class="$style['img-clock']" />
        </div>
        <div
          class="col-8 d-flex  align-items-center justify-content-between"
          :class="$style['konsumsi-kanan']"
        >
          <div class="d-flex align-items-center justify-content-center">
            <img src="../../assets/img/obat-imfinzi.png" alt="" :class="$style['img-obat']" />
            <div class="ml-3">
              <p class="m-0" :class="$style['text-obat']">
                <strong>{{ dataPatient.programs[0].programType.name }}</strong>
              </p>
              <p class="m-0" :class="$style['text-obat']">
                <strong>{{ jadwal.jam }}</strong>
              </p>
            </div>
          </div>
          <div>
            <a-checkbox
              v-model:checked="jadwal.checked"
              @click="
                toggleChecked(
                  jadwal.jam,
                  dataPatient.id,
                  dataPatient.programs[0].programType.name,
                  cardIndex,
                )
              "
            ></a-checkbox>
          </div>
        </div>
      </div> -->
    </div>

    <div v-if="isProgramExist && articleSpecial.length">
      <div class="w-100 mt-5 mb-3">
        <div class="d-flex flex-row justify-content-between align-items-baseline">
          <h5 :class="$style['title-section']"><strong>Artikel Khusus Untukmu</strong></h5>
          <p style="cursor: pointer;" @click="handleArticle">Lihat Semua</p>
        </div>
      </div>
      <div class="row flex-nowrap overflow-auto" v-if="articleSpecial.length">
        <div
          v-for="article in articleSpecial"
          :key="article.id"
          class="col-auto"
          style="min-width: 250px; scroll-snap-type: x mandatory; scroll-behavior: smooth;"
        >
          <div class="card" :class="$style['custom-card']">
            <img
              :src="backendUrl + article.image"
              class="card-img-top"
              alt="Olahraga"
              style="border-radius: 12px; object-fit: cover"
            />

            <!-- <div :class="$style['overlay']"></div> -->
            <div class="px-0 px-sm-5">
              <h5 :class="$style['card-description']" style="color: #831A53">
                {{ formatDate(article.createdAt) }}
              </h5>
              <h5 :class="$style['card-title']">{{ truncateTitle(article.title) }}</h5>
              <h5 :class="$style['card-description']">{{ truncateDescription(article.body) }}</h5>
              <a-button
                type="primary"
                :class="$style['custom-button']"
                @click="handleArticleDetail(article.id)"
              >
                <img
                  src="../../assets/img/baca.png"
                  alt=""
                  style="width: 24px;height: 20px;margin-right: 10px;"
                />
                <p style="font-size: 24px">Baca</p></a-button
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <a-modal
      v-model:visible="toggleNps"
      :title="`Pertanyaan ke ${npsCurrentQuestion + 1}/3`"
      centered
      :footer="null"
      @cancel="handleCancel"
    >
      <p class="m-0 ">
        {{ npsCurrentQuestion + 1 }}. {{ npsQuestion[npsCurrentQuestion]?.question }}
      </p>
      <div class="w-100 h-100 mt-3">
        <a-button
          v-for="idx in 10"
          :key="idx"
          style="width: 45px; height: 45px; border-radius: 8px; text-align: center; margin-right: 2px;"
          @click="handleAnswer(npsQuestion[npsCurrentQuestion]?.id, idx)"
          >{{ idx }}</a-button
        >
      </div>
      <div class="d-flex mt-1" style="align-items: center; justify-content: space-between;">
        <p style="font-size: 12px;">Sangat Tidak Mungkin</p>
        <p style="font-size: 12px;">Sangat Mungkin</p>
      </div>
      <div class="d-flex mt-4" style="align-items: center; justify-content: space-between;">
        <a-button
          :disabled="npsCurrentQuestion === 0"
          @click="previousQuestion"
          :class="$style['custom-button-yes']"
          class="text-white"
          >Sebelumnya</a-button
        >
        <a-button
          :disabled="!npsAnswers[npsCurrentQuestion]"
          @click="nextOrSubmit"
          :class="$style['custom-button-yes']"
          class="text-white"
          >{{ npsCurrentQuestion === 2 ? 'Kirim' : 'Selanjutnya' }}</a-button
        >
      </div>
    </a-modal>
    <a-modal
      v-model:visible="visibleEmailApproved"
      title="Verifikasi Email"
      centered
      :footer="null"
    >
      <div class="d-flex flex-column align-items-center justify-content-center">
        <img src="../../assets/img/alert.png" alt="" style="width: 200px;height: auto;" />
        <p class="text-center">
          Silahkan Cek Email Anda Untuk Melakukan Verifikasi dan Melanjutkan Pendaftaran
        </p>
      </div>
    </a-modal>
  </div>
</template>

<script>
/* eslint-disable */
import {
  onMounted,
  onBeforeUnmount,
  ref,
  reactive,
  createVNode,
  toRef,
  toRefs,
  watch,
  computed,
  onBeforeMount,
} from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import Banner from '../../components/Banners'
import { baseURL } from '@/services/axios'

import {
  getPatientProgram,
  checkDailyReminder,
  getListNews,
  getListNewsByProgram,
  getPatientNps,
  createPatientNps,
  getPatientMaster,
  sendEmailVerification,
  getVideo,
} from '../../services/axios/pulih'
import {
  CheckOutlined,
  ToolOutlined,
  PhoneOutlined,
  CloudUploadOutlined,
  CloudDownloadOutlined,
  InfoCircleOutlined,
  HomeOutlined,
  ContactsOutlined,
  EyeOutlined,
} from '@ant-design/icons-vue'
import { Modal, notification, message } from 'ant-design-vue'
import { useForm } from '@ant-design-vue/use'
import axios from 'axios'
import { getAssets, getCustomer } from '../../services/axios/modc/assets'
import lottie from '../../../scripts/Timer.json'
import caution from '../../../scripts/Caution.json'
import { settings } from 'nprogress'

export default {
  components: {
    Banner,
    EyeOutlined,
  },
  setup() {
    /* eslint-disable */
    const route = useRoute()
    const router = useRouter()
    let store = useStore()
    let settings = computed(() => store.getters.settings)
    const dataHasilTes = computed(() => store.state.dataHasilTes)
    let checked = ref(false)
    const dataPatient = ref({})
    const step = ref(0)
    const steps = ref({
      persetujuan: false,
      tinjauHasil: false,
      unggahKwitansi: false,
      konfirmasiDokter: false,
      unggahResep: false,
      verifikasi: false,
    })
    const isAurora = ref(false)
    const videos = ref([])
    const isDrugReminderCreated = ref(false)
    const drugReminders = ref([])
    const articles = ref([])
    const backendUrl = ref('https://pulih-api-prod.digitala.id/')
    const toggleNps = ref(false)
    const npsQuestion = ref([])
    const npsAnswers = ref([])
    const visibleEmailApproved = ref(false)

    const npsCurrentQuestion = ref(0)

    const handleAnswer = (id, skor) => {
      console.log(id, 'iniid')
      npsAnswers.value[npsCurrentQuestion.value] = {
        npsMasterId: id,
        skor,
      }
    }

    const nextOrSubmit = () => {
      if (npsCurrentQuestion.value < 2) {
        npsCurrentQuestion.value++
      } else {
        submitAnswers()
      }
    }

    const previousQuestion = () => {
      if (npsCurrentQuestion.value > 0) {
        npsCurrentQuestion.value--
      }
    }

    const submitAnswers = async () => {
      console.log('Submitting answers:', npsAnswers.value)
      const userData = JSON.parse(localStorage.getItem('userData'))
      const res = await createPatientNps(npsAnswers.value, userData.user.id)
      console.log(res, 'hasilcreatenps')
      toggleNps.value = false // Tutup modal saat submit
    }
    const handleMenu = type => {
      console.log(type)
      if (type === 'profil') {
        router.push('/profil')
      } else if (type === 'program-pulih') {
        if (dataPatient.value.programs.length) {
          router.push('/mainProgram')
        } else {
          if (!dataPatient.value.emailApproved) {
            visibleEmailApproved.value = true
            const a = document.createElement('a')
            a.href = 'mail.google.com'
            a.target = '_blank'
            a.click()
          } else {
            router.push('/program')
          }
        }
      } else if (type === 'bantuan') {
        router.push('/bantuan')
      }
    }

    const handleDrugHistory = () => {
      router.push('/history')
    }
    const toggleChecked = async (jam, patientId, programTypeName, cardIndex) => {
      console.log(jam, patientId, programTypeName, 'iniketikaceklis')
      let [hour, minute] = jam.split(':')
      const res = await checkDailyReminder(hour, minute, patientId, programTypeName)
      const stringifiedCards = JSON.stringify(drugReminders.value)
      const lastUpdate = new Date()

      // Extract the year, month, and day
      const year = lastUpdate.getFullYear()
      const month = String(lastUpdate.getMonth() + 1).padStart(2, '0') // Months are zero-based, so add 1
      const day = String(lastUpdate.getDate()).padStart(2, '0')

      // Format as YYYY-MM-DD
      const formattedDate = `${year}-${month}-${day}`
      localStorage.setItem('lastUpdate', JSON.stringify(formattedDate))
      // console.log('stringifiedcars', stringifiedCards)
      localStorage.setItem('jadwals', stringifiedCards)
      console.log(res, 'afterdailycheck')
    }
    const isWithinSevenDays = targetDateString => {
      const targetDate = new Date(targetDateString)

      // Get the current date
      const today = new Date()

      // Calculate the date 7 days before the target date
      const sevenDaysBefore = new Date(targetDate)
      sevenDaysBefore.setDate(targetDate.getDate() - 7)

      // Check if today's date is within the range of 7 days before up to the target date
      return today >= sevenDaysBefore || today >= targetDate
    }
    const handleArticle = () => {
      router.push('/article')
    }
    const handleVideo = () => {
      router.push('/video-article')
    }
    const handleArticleDetail = id => {
      router.push('/article/' + id)
    }
    const handleCancel = async () => {
      const userData = JSON.parse(localStorage.getItem('userData'))
      console.log(userData, 'inidiskipnps')
      const res = await createPatientNps([], userData.user.id, true)
      toggleNps.value = false
    }
    const isProgramExist = ref(false)
    const articleSpecial = ref([])
    // onBeforeMount(async () => {
    const initialData = async () => {
      const userData = JSON.parse(localStorage.getItem('userData'))
      const res = await getPatientProgram(userData.user.code)

      const resNpsMaster = await getPatientMaster()
      const resNps = await getPatientNps(userData.user.id)
      const resVideo = await getVideo()
      videos.value = resVideo
      console.log(resNps, 'ininps')
      npsQuestion.value = resNpsMaster
      console.log(resNps, 'ini res pasien')
      console.log(resNpsMaster, 'ini res master')

      console.log(toggleNps.value, 'initogglenps')
      console.log(res, 'inindatapatientbycode')
      dataPatient.value = res
      console.log(!dataPatient.value.emailApproved)
      if (!dataPatient.value.emailApproved) {
        const email = dataPatient.value.email
        console.log(email, 'iniemailpasien')

        visibleEmailApproved.value = true
        await sendEmailVerification(email)

        // Ambil domain email
        const emailDomain = email.split('@')[1]

        // Redirect berdasarkan domain
        let emailUrl = ''

        if (emailDomain === 'gmail.com') {
          emailUrl = 'https://mail.google.com'
        } else if (emailDomain === 'yahoo.com') {
          emailUrl = 'https://mail.yahoo.com'
        } else if (emailDomain === 'outlook.com' || emailDomain === 'hotmail.com') {
          emailUrl = 'https://outlook.live.com'
        } else {
          console.log('Domain email tidak dikenali.')
        }

        // Redirect ke layanan email jika ada URL
        if (emailUrl) {
          const a = document.createElement('a')
          a.href = emailUrl
          a.target = '_blank'
          document.body.appendChild(a) // Tambahkan ke DOM agar bisa di-click
          a.click()
          document.body.removeChild(a) // Hapus elemen setelah klik
        }
      }
      const response = await getListNews()
      if (response.length > 3) {
        articles.value = response.slice(0, 3)
      } else {
        articles.value = response
      }
      if (res.programs.length) {
        store.dispatch('updateProgramId', dataPatient.value.programs[0].id)

        if (!resNps.id && dataPatient.value.programs[0].isDrugsTaken) {
          toggleNps.value = true
        }
        isProgramExist.value = true
        if (dataPatient.value.programs[0].category === 'aurora') {
          console.log('rora')
          if (res.programs.length && !res.programs[0].testLab) {
            step.value = 1
          }
          if (res.programs.length && res.programs[0].testLab && !res.programs[0].insurance) {
            step.value = 2
          }
          if (
            res.programs.length &&
            res.programs[0].testLab &&
            res.programs[0].testLab.id &&
            res.programs[0].insurance &&
            res.programs[0].insurance.id
          ) {
            step.value = 3
          }
          if (res.programs[0].checkPoint === 3) {
            step.value = 4
          }
          if (res.programs[0].checkPoint === 4) {
            step.value = 5
          }
          if (res.programs[0].checkPoint === 5) {
            step.value = 6
          }
          console.log(step.value, 'ini di atas switchrora')

          switch (step.value) {
            case 1:
              steps.value.persetujuan = true
              break

            case 2:
              steps.value.persetujuan = true
              steps.value.tinjauHasil = true
              break

            case 3:
              steps.value.persetujuan = true
              steps.value.tinjauHasil = true
              steps.value.unggahKwitansi = true
              break

            case 4:
              steps.value.persetujuan = true
              steps.value.tinjauHasil = true
              steps.value.unggahKwitansi = true
              steps.value.konfirmasiDokter = true
              break

            case 5:
              steps.value.persetujuan = true
              steps.value.tinjauHasil = true
              steps.value.unggahKwitansi = true
              steps.value.konfirmasiDokter = true
              steps.value.unggahResep = true
              break

            case 6:
              steps.value.persetujuan = true
              steps.value.tinjauHasil = true
              steps.value.unggahKwitansi = true
              steps.value.konfirmasiDokter = true
              steps.value.unggahResep = true
              steps.value.verifikasi = true
              break

            default:
              break
          }
        } else {
          if (res.programs.length) {
            console.log('pap')

            if (res.programs.length && !res.programs[0].testLab) {
              step.value = 1
            }
            if (res.programs.length && res.programs[0].testLab) {
              step.value = 2
            }
            if (res.programs[0].checkPoint === 3) {
              step.value = 3
            }
            if (res.programs[0].checkPoint === 4) {
              step.value = 4
            }
            if (res.programs[0].checkPoint === 5) {
              step.value = 5
            }

            console.log(step.value, 'ini di atas switch')
            switch (step.value) {
              case 1:
                steps.value.persetujuan = true
                break
              case 2:
                steps.value.persetujuan = true
                steps.value.tinjauHasil = true
                break

              case 3:
                steps.value.persetujuan = true
                steps.value.tinjauHasil = true
                steps.value.konfirmasiDokter = true
                break

              case 4:
                steps.value.persetujuan = true
                steps.value.tinjauHasil = true
                steps.value.konfirmasiDokter = true
                steps.value.unggahResep = true
                break

              case 5:
                steps.value.persetujuan = true
                steps.value.tinjauHasil = true
                steps.value.konfirmasiDokter = true
                steps.value.unggahResep = true
                steps.value.verifikasi = true
                break

              default:
                break
            }
          } else {
            step.value = 0
          }
        }
        const response = await getListNewsByProgram(dataPatient.value.programs[0].programType.name)
        if (response.length > 3) {
          articleSpecial.value = response.slice(0, 3)
        } else {
          articleSpecial.value = response
        }
        console.log(articleSpecial.value, 'iniartikelspesial')
      } else {
        step.value = 0
      }
      store.dispatch('changeBannerStep', step.value)
      if (dataPatient.value.programs.length) {
        if (dataPatient.value.programs[0].category === 'aurora') {
          isAurora.value = true
          store.dispatch('changeIsAuroraStatus', true)
        }
        store.dispatch(
          'changeRedemptionStatusExist',
          dataPatient.value.programs[0].medicalRedemptions.length ? true : false,
        )
        if (
          (dataPatient.value.programs[0].medicalRedemptions.length &&
            dataPatient.value.programs[0].medicalRedemptions[0].status) ||
          dataPatient.value.programs[0].isDrugsTaken
        ) {
          store.dispatch(
            'changeRedemptionStatus',
            dataPatient.value.programs[0].isDrugsTaken ? true : false,
          )
        }
        console.log('inireminderdate', dataPatient.value.programs[0].reminderDate)
        store.dispatch('updateProgramName', dataPatient.value.programs[0].programType.name)
        console.log(isWithinSevenDays(dataPatient.value.programs[0].reminderDate))
        store.dispatch(
          'updateIsExtendingExist',
          isWithinSevenDays(dataPatient.value.programs[0].reminderDate),
        )
      }
      let jadwals = JSON.parse(localStorage.getItem('jadwals'))
      if (jadwals && jadwals.length) {
        store.dispatch('updateJadwalExist', jadwals.length ? true : false)

        const lastUpdate = JSON.parse(localStorage.getItem('lastUpdate'))
        drugReminders.value = jadwals
        isDrugReminderCreated.value = true
        const currentDate = new Date()

        // Extract the year, month, and day
        const year = currentDate.getFullYear()
        const month = String(currentDate.getMonth() + 1).padStart(2, '0') // Months are zero-based, so add 1
        const day = String(currentDate.getDate()).padStart(2, '0')

        // Format as YYYY-MM-DD
        const formattedDate = `${year}-${month}-${day}`
        if (lastUpdate < formattedDate) {
          jadwals = jadwals.map(e => {
            e.checked = false
            return e
          })
          const stringifiedCards = JSON.stringify(jadwals)
          localStorage.setItem('jadwals', stringifiedCards)
        }
      }
    }
    onMounted(() => {
      // console.log(steps.value)
      // console.log(step.value, 'inistepnya')
      initialData()
    })
    const truncateTitle = (text, maxLength = 35) => {
      if (text.length > maxLength) {
        return text.substring(0, maxLength) + '...'
      }
      return text
    }
    const truncateDescription = (text, maxLength = 100) => {
      if (text.length > maxLength) {
        return text.substring(0, maxLength) + '...'
      }
      return text
    }
    const formatDate = dateString => {
      const options = { year: 'numeric', month: 'long', day: 'numeric', weekday: 'long' }
      return new Date(dateString).toLocaleDateString('id-ID', options)
    }

    onBeforeUnmount(() => {})
    const stepComputed = computed(() => step.value)

    return {
      handleCancel,
      visibleEmailApproved,
      imageLoaded: false,
      truncateDescription,
      formatDate,
      articleSpecial,
      isProgramExist,
      toggleNps,
      npsQuestion,
      npsAnswers,
      npsCurrentQuestion,
      handleAnswer,
      nextOrSubmit,
      previousQuestion,
      submitAnswers,
      handleArticleDetail,
      handleArticle,
      handleVideo,
      truncateTitle,
      backendUrl,
      articles,
      handleDrugHistory,
      dataPatient,
      drugReminders,
      toggleChecked,
      isDrugReminderCreated,
      isAurora,
      step,
      steps,
      dataHasilTes,
      handleMenu,
      checked,
      settings,
      lottie,
      caution,
      stepComputed,
      videos,
    }
  },
}
</script>

<style lang="scss" module>
@import '@/components/Auth/style.module.scss';

.no-schedule {
  width: 80px;
}

.carousel-container {
  display: flex;
  overflow-y: hidden; /* Mengaktifkan scrolling horizontal */
  scroll-snap-type: x mandatory; /* Mengaktifkan snap secara horizontal */
  -webkit-overflow-scrolling: touch; /* Memperbaiki swipe untuk perangkat mobile */
  scroll-behavior: smooth; /* Animasi smooth saat scroll */
  width: 100%;
  gap: 15px;
}

.carousel-slide {
  flex: 0 0 auto; /* Agar setiap slide tetap satu ukuran */
  width: 100%; /* Sesuaikan dengan kebutuhan Anda */
  scroll-snap-align: start; /* Snap slide ke posisi awal */
  display: flex; /* Mempertahankan struktur layout di dalam slide */
}
.main-menu-item {
  width: 100%;
  height: auto;
  object-fit: cover;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  border-radius: 8px;
  cursor: pointer;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.main-menu-item:hover {
  transform: scale(1.05);
  /* Slightly increase the size */
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2), 0 12px 40px 0 rgba(0, 0, 0, 0.24);
  /* Optionally enhance the shadow */
}

.title-section {
  font-size: 20px;
  color: #4f125f;
  width: 200px;
}
.custom-card {
  position: relative;
  overflow: hidden;
  cursor: pointer;
  border-radius: 24px;
  padding: 20px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  display: flex;
  flex-direction: row;
  max-width: 930px;
  @media (max-width: 1440px) {
    display: flex;
    flex-direction: row;
    // height: 300px;
    // max-width: 308px;
    // height: 45px;
    max-width: 930px;
    // font-size: 16px;
  }
  @media (max-width: 1024px) {
    display: flex;
    flex-direction: row;
    // height: 300px;
    // max-width: 308px;
    // height: 45px;
    max-width: 830px;
    // font-size: 16px;
  }
  // Ukuran default untuk desktop
  @media (max-width: 992px) {
    display: flex;
    flex-direction: row;
    // min-height: 380px;
    max-width: 630px;
  }
  @media (min-width: 576px) and (max-width: 769px) {
    display: flex;
    flex-direction: row;
    min-height: 250px;
  }
  //INI MOBILE
  @media (max-width: 575px) {
    display: flex;
    flex-direction: column;
    // height: 320px;
    min-height: 420px;
    padding: 10px;
  }
}
.custom-card img {
  width: 100%;
  object-fit: cover;
  // height: 200px;
  @media (min-width: 1024px) {
    display: flex;
    flex-direction: row;
    width: 40%;
    height: auto;
    height: 250px;
    // height: 100%;
    // max-width: 308px;
    // height: 45px;
    // font-size: 16px;
  }
  @media (min-width: 576px) and (max-width: 768px) {
    width: 40%;
    height: auto;
    object-fit: cover;
    max-height: 200px;
  }
  @media (max-width: 575px) {
    width: 100%;
    height: auto;
    object-fit: cover;
    max-height: 200px;
  }
  /* Sesuaikan dengan kebutuhan, bisa juga height: 100% jika perlu */
}

.custom-button {
  position: absolute !important;
  display: flex !important;
  align-items: baseline;
  justify-content: center;
  width: 100%;
  max-width: 308px;
  height: 50px !important;
  bottom: 20px;
  background-color: #3d1053 !important;
  color: white;
  font-size: 18px;
  border-radius: 12px !important;
  @media (max-width: 1440px) {
    max-width: 308px;
    height: 45px;
    font-size: 16px;
  }
  @media (max-width: 1024px) {
    max-width: 248px;
    bottom: 24px;
  }
  @media (max-width: 768px) {
    max-width: 178px;
    height: 45px;
    bottom: 30px;
    font-size: 12px;
  }

  @media (max-width: 577px) {
    max-width: 93.5%;
    height: 50px !important;
    font-size: 14px;
    bottom: 10px;
  }
}

.card-title {
  font-weight: bold;
  font-size: 27px;
  text-align: left;

  @media (max-width: 1024px) {
    font-size: 24px;
  }
  @media (max-width: 768px) {
    // font-size: 14px;
  }

  @media (max-width: 768px) {
    font-size: 20px;
  }
}

.card-description {
  font-size: 16px;
  text-align: left;
  margin-top: 15px;

  @media (max-width: 768px) {
    // font-size: 11px;
    margin-top: 10px;
  }

  @media (max-width: 768px) {
    font-size: 12px;
    margin-top: 5px;
  }
}

.custom-card:hover {
  transform: scale(1.01);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

/* Menargetkan elemen checkbox internal secara global */
:global(.ant-checkbox-inner) {
  width: 20px !important;
  /* Mengatur lebar */
  height: 20px !important;
  /* Mengatur tinggi */
  border-radius: 3px !important;
  /* Opsional: Menghapus border-radius */

  /* Center horizontal */
  border: 1px solid #707072 !important;
}

/* Opsi tambahan: Menyesuaikan ukuran tanda centang jika perlu */
:global(.ant-checkbox-inner::after) {
  /* Atur lebar tanda centang */
  // width: 20px !important;
  // height: 20px !important;
  /* Atur tinggi tanda centang */
  /* Pusatkan secara vertikal */
  // border-radius: 8px !important;
  display: flex !important;
  /* Menggunakan flexbox untuk align centang */
  align-items: center !important;
  /* Center vertical */
  justify-content: center !important;
  // top: 20px !important;
  left: 5px !important;
  // transform: scale(1) !important;
  // rotate: 40deg;
  /* Menyesuaikan posisi dan skala */
}

.custom-input-email-login {
  border: none !important;
  border-bottom: 1px solid #e4e9f0 !important;
  border-radius: 0 !important;

  &:focus {
    border-bottom: 1px solid #800152 !important;
  }

  &:hover {
    border-bottom: 1px solid #800152 !important;
  }
}

.custom-input-password-login {
  border: none !important;
  border-bottom: 1px solid #e4e9f0 !important;
  border-radius: 0 !important;

  &:focus-within {
    border-bottom: 1px solid #800152 !important;
  }

  &:hover {
    border-bottom: 1px solid #800152 !important;
  }
}

.custom-forget-pass {
  color: #800152 !important;
  border-bottom: 1px solid transparent;
  transition: 0.3s all ease-in-out;

  &:hover {
    border-bottom: 1px solid #800152 !important;
  }
}

.custom-register-link {
  color: #800152 !important;
  font-weight: 700;
  border-bottom: 1px solid transparent;
  transition: 0.3s all ease-in-out;

  &:hover {
    border-bottom: 1px solid #800152 !important;
  }
}

.custom-form-item {
  label::before {
    content: '' !important;
  }
}

.custom-button-yes {
  // width: 100%;
  height: 20px !important;
  font-weight: 500;
  font-size: 8px !important;
  // text-transform: uppercase;
  background: linear-gradient(to right, #eda702, #c76b1e) !important;
  border: 1px solid #eda702 !important;
  transition: all 0.3s ease-in-out;

  &:hover {
    color: #eda702 !important;
    background: transparent !important;
  }
}

.custom-button-no {
  // width: 100%;
  height: 20px !important;
  font-weight: 500;
  font-size: 8px !important;
  text-transform: uppercase;
  background: linear-gradient(to right, #800251, #ba0672) !important;
  transition: all 0.3s ease-in-out;
  border: 1px solid #800251 !important;

  &:global(.ant-btn-primary:hover) {
    border: 1px solid #800251 !important;
  }

  &:hover {
    color: #800251 !important;
    background: transparent !important;
    border: 1px solid #800251 !important;
  }
}

.custom-container-login {
  width: 75% !important;
}

.custom-text-login {
  color: white;
}

@media (max-width: 960px) {
  .custom-container-login {
    width: 100% !important;
  }
}

.step-title {
  font-size: 14px;
}

.img-banner {
  width: 130px;
  height: 80px;
}

.banner-title {
  font-size: 10px;
}

.step {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 30px;
}

.line {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 30px;
  background-color: #4f4f4f;
  height: 1px;
}

.img-step {
  width: 32px;
  height: 32px;
}

.step p {
  font-size: 8px;
}
.img-clock {
  width: 38px;
  height: 35px;
}
.img-clock-empty {
  width: 100px;
  height: 75px;
}

.konsumsi-section {
  width: 100%;
  height: 100%;
}

.img-obat {
  width: 32px;
  height: auto;
  object-fit: cover;
}

.text-obat {
  font-size: 12px;
}

.konsumsi-kanan {
  background-color: #ffffff;
  border-radius: 0 20px 20px 0;
  padding: 20px 30px 20px 30px;
}

.article-item {
  width: 280px;
  height: 130px;
  border-radius: 20px;
  margin-top: 10px;
}

.menu-title {
  font-size: 10px;
  height: auto;
}

//DEKSTOP
@media screen and (min-width: 1440px) {
  .no-schedule {
    width: 400px;
  }
  .konsumsi-section {
    width: 100%;
    height: 70px;
  }
  .main-menu-item {
    width: 100%;
    height: auto;
    object-fit: cover;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    border-radius: 30px;
  }

  .title-section {
    font-size: 32px;
    color: #4f125f;
    width: auto;
  }

  .img-banner {
    width: 327.75px;
    height: 205.68px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 50px;
  }

  .banner-title {
    font-size: 28px;
    width: 80%;
    margin-bottom: 50px;
  }

  .custom-button-yes {
    height: 40px !important;
    width: 40%;
    font-size: 16px !important;
  }

  .custom-button-no {
    height: 60px !important;
    width: 80%;
    font-size: 24px !important;
    margin-top: 5px;
  }

  .step {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 80px;
  }

  .line {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 60px;
    background-color: #4f4f4f;
    height: 1px;
  }

  .img-step {
    width: 64px;
    height: 64px;
  }

  .step p {
    font-size: 16px;
  }

  .title-step {
    height: 240px;
  }

  .step-title {
    font-size: 32px;
    width: 130px;
    text-align: center;
  }

  .menu-title {
    font-size: 28px;
  }

  .img-clock {
    width: 88px;
    height: 85px;
  }

  .konsumsi-section {
    width: 100%;
    height: 200px;
  }

  .img-obat {
    width: 64px;
    height: auto;
  }

  .text-obat {
    font-size: 24px;
  }

  .konsumsi-kanan {
    background-color: #ffffff;
    border-radius: 0 20px 20px 0;
    padding: 50px 70px 50px 70px;
  }

  .article-item {
    width: 400px;
    height: 200px;
    border-radius: 20px;
    margin-top: 10px;
  }

  /* Menargetkan elemen checkbox internal secara global */
  :global(.ant-checkbox-inner) {
    width: 56px !important;
    /* Mengatur lebar */
    height: 56px !important;
    /* Mengatur tinggi */
    border-radius: 8px !important;
    /* Opsional: Menghapus border-radius */

    /* Center horizontal */
    border: 1px solid #707072 !important;
  }

  /* Opsi tambahan: Menyesuaikan ukuran tanda centang jika perlu */
  :global(.ant-checkbox-inner::after) {
    /* Atur lebar tanda centang */
    // width: 20px !important;
    // height: 20px !important;
    /* Atur tinggi tanda centang */
    /* Pusatkan secara vertikal */
    // border-radius: 8px !important;
    display: flex !important;
    /* Menggunakan flexbox untuk align centang */
    align-items: center !important;
    /* Center vertical */
    justify-content: center !important;
    top: 20px !important;
    left: 24px !important;
    transform: scale(3) !important;
    rotate: 40deg;
    /* Menyesuaikan posisi dan skala */
  }
}

//TABLET
@media screen and (max-width: 1439px) and (min-width: 768px) {
  .menu-title {
    font-size: 18px;
    height: auto;
  }
  .no-schedule {
    width: 300px;
  }
  .konsumsi-section {
    width: 100%;
    height: 70px;
  }
  .main-menu-item {
    width: 100%;
    height: auto;
    object-fit: cover;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    border-radius: 20px;
  }

  .title-section {
    font-size: 32px;
    color: #4f125f;
    width: auto;
  }

  .img-banner {
    width: 230px;
    height: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .step-title {
    font-size: 32px;
    // width: 130px;
    text-align: center;
  }

  .banner-title {
    font-size: 16px;
    width: 80%;
  }

  .custom-button-yes {
    height: 30px !important;
    width: 30%;
    font-size: 12px !important;
  }

  .custom-button-no {
    height: 30px !important;
    width: 80%;
    font-size: 12px !important;
    margin-top: 5px;
  }

  .step {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 70px;
  }

  .line {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 70px;
    background-color: #4f4f4f;
    height: 1px;
  }

  .img-step {
    width: 50px;
    height: 50px;
  }

  .step p {
    font-size: 12px;
  }

  .title-step {
    height: 240px;
  }

  .img-clock {
    width: 48px;
    height: 45px;
  }

  .konsumsi-section {
    width: 100%;
    height: 100px;
  }

  .article-item {
    width: 280px;
    height: 130px;
    border-radius: 20px;
    margin-top: 10px;
  }
}
.overlay-container {
  position: relative;
  overflow: hidden; // Agar overlay tidak melampaui container

  .overlay-image {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover; // Menjaga proporsi gambar
    border-radius: 20px; // Radius gambar sesuai desain
  }

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); // Overlay gelap dengan transparansi
    border-radius: inherit; // Mengikuti radius gambar
    z-index: 1; // Letakkan overlay di atas gambar
    pointer-events: none; // Agar overlay tidak mengganggu interaksi
  }
}
</style>
