<template>
  <div class="container">
    <a-button
      @click="$router.back()"
      style="border-radius: 20px; background-image: linear-gradient(to right, #1D2671 , #830051); color: white;"
      ><LeftOutlined /> Back</a-button
    >
    <div class="row">
      <!-- BATAS -->
      <div class="col-12 col-md-6 mt-4" v-for="(programType, idx) in programTypeList" :key="idx">
        <a-card style="border-radius: 12px;">
          <div class="row">
            <div class="col-5 d-flex align-items-center justify-content-center">
              <img
                :src="`https://pulih-api-prod.digitala.id${programType.image}`"
                alt=""
                class=""
                style="object-fit: cover; width: 100%;height: auto"
              />
            </div>
            <div class="col-7 p-0 px-2">
              <p>
                <strong class="desc-title ">{{ programType.name }}</strong>
              </p>
              <p class="program-desc m-0 w-100">
                {{ programType.description }}
              </p>
            </div>
          </div>
          <br />
          <br />
          <br />

          <a-button
            type="primary"
            class="w-100 h-auto p-2 py-3 custom-button"
            style="position: absolute;left: 0px;bottom: 0px;"
            @click="handlePage(programType.id)"
            >Ajukan Program PULIH</a-button
          >
        </a-card>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, ref, reactive, toRaw, createVNode } from 'vue'
import { useStore } from 'vuex'
import { Modal, notification } from 'ant-design-vue'
import {
  ExclamationCircleOutlined,
  CheckCircleTwoTone,
  UploadOutlined,
  LeftOutlined,
} from '@ant-design/icons-vue'
import { useRoute, useRouter } from 'vue-router'
import { getListProgramType } from '../../services/axios/pulih'
export default {
  components: { LeftOutlined },
  setup() {
    const store = useStore()
    const route = useRoute()
    const router = useRouter()
    let data = ref({})
    const profileData = reactive({
      idPasien: '2023JKT0104',
      namaKTP: 'Test Aurora',
      tanggalLahir: '1999-05-22',
      noKtp: '123456789012355',
      jenisKelamin: 'Pria',
      email: 'farizdenada@gmail.com',
      namaPerwakilan: 'Susu',
      telepon: '12345678',
      hubungan: 'Aa',
    })
    let showUploadAvatar = ref(false)
    let headers = ref({
      smail: '*_~',
    })
    let imgDataUrl = reactive('')
    let backendUrl = ref(process.env.VUE_APP_BACKEND_URL)
    const id = ref('12asoka-192kkc-9j900')
    const handlePage = programTypeId => {
      store.dispatch('updateProgramType', programTypeId)
      router.push(`/mainProgram`)
    }
    const programTypeList = ref()
    onMounted(async () => {
      // fetchMyData()
      const res = await getListProgramType()
      // const program = route.params.program
      let filteredProgram = ''
      const program = store.state.program
      if (!program) {
        router.push('/dashboard')
      }
      if (program === 'aurora') {
        filteredProgram = res.filter(e => e.isAurora === true)
      } else {
        filteredProgram = res.filter(e => e.isPap === true)
      }
      programTypeList.value = filteredProgram
      console.log(res, 'iniProgramType')
    })

    const fetchMyData = async () => {
      const rawData = await store.dispatch('admin/FETCH_ADMIN_BY_ID')
      // console.log('fetchMyData => ', rawData)
      rawData.newPassword = null
      data.value = rawData
    }

    const toggleShowUploadAvatar = () => {
      showUploadAvatar.value = !showUploadAvatar.value
    }

    const cropSuccess = (imgDataUrl, field) => {
      console.log('-------- crop success --------')
      imgDataUrl = imgDataUrl
    }

    const cropUploadSuccess = (jsonData, field) => {
      console.log('-------- upload success --------')
      console.log(jsonData)
      console.log('field: ' + field)
      toggleShowUploadAvatar()
      fetchMyData()
    }

    const cropUploadFail = (status, field) => {
      console.log('-------- upload fail --------')
      console.log(status)
      console.log('field: ' + field)
      toggleShowUploadAvatar()
      fetchMyData()
    }

    const handleChangeGender = genderValue => {
      data.value.gender = genderValue
    }

    const changeAdminProfile = async () => {
      Modal.confirm({
        title: `Apa anda yakin ingin mengubah profil?`,
        icon: createVNode(ExclamationCircleOutlined),
        async onOk() {
          try {
            const newAdminProfile = data.value
            const updateProfile = await store.dispatch('admin/UPDATE_ADMIN_PROFILE', {
              newAdminProfile,
            })
            console.log('updateProfile', updateProfile)
            if (updateProfile.isSuccess === true) {
              notification.success({
                message: 'Sukses',
                description: 'Berhasil Mengubah Profil',
              })
            } else {
              notification.error({
                message: 'Error!',
                description: 'Gagal Mengubah Profil!',
              })
            }
            fetchMyData()
          } catch (err) {
            console.log(err)
          }
        },
        onCancel() {},
      })
    }

    return {
      programTypeList,
      handlePage,
      profileData,
      data,
      backendUrl,
      showUploadAvatar,
      headers,
      toggleShowUploadAvatar,
      cropSuccess,
      cropUploadSuccess,
      cropUploadFail,
      imgDataUrl,
      handleChangeGender,
      changeAdminProfile,
    }
  },
}
</script>

<style lang="scss">
@import '../../mixins.scss';

.custom-button-yes {
  // width: 100%;
  height: 60px !important;
  // font-weight: 200;
  font-size: 24px !important;
  text-transform: uppercase;
  background: linear-gradient(to right, #eda702, #c76b1e) !important;
  border: 1px solid #eda702 !important;
  transition: all 0.3s ease-in-out;

  &:hover {
    color: #eda702 !important;
    background: transparent !important;
  }
}

.custom-button-no {
  // width: 100%;
  height: 60px !important;
  // font-weight: 500;
  font-size: 24px;
  text-transform: uppercase;
  background: linear-gradient(to right, #800251, #ba0672) !important;
  transition: all 0.3s ease-in-out;
  border: 1px solid #800251 !important;

  &:global(.ant-btn-primary:hover) {
    border: 1px solid #800251 !important;
  }

  &:hover {
    color: #800251 !important;
    background: transparent !important;
  }
}

.program-desc {
  width: 250px;
  margin-left: 40px;
}

.custom-button-yes {
  // width: 100%;
  // height: 20px !important;
  font-size: 24px !important;
  text-transform: uppercase;
  background: linear-gradient(to right, #eda702, #c76b1e) !important;
  border: 1px solid #eda702 !important;
  transition: all 0.3s ease-in-out;

  &:hover {
    color: #eda702 !important;
    background: transparent !important;
  }
}

.custom-button-no {
  // width: 100%;
  // height: 20px !important;
  // font-weight: 500;
  font-size: 24px !important;
  text-transform: uppercase;
  background: linear-gradient(to right, #800251, #ba0672) !important;
  transition: all 0.3s ease-in-out;
  border: 1px solid #800251 !important;

  &:global(.ant-btn-primary:hover) {
    border: 1px solid #800251 !important;
  }

  &:hover {
    color: #800251 !important;
    background: transparent !important;
  }
}

.form-item {
  display: flex;
  margin-bottom: 10px;
}

.col-12 col-sm-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%;
}

.profile-header {
  background-color: #9370db;
  /* Dark orchid color, adjust as needed */
  color: white;
  text-align: center;
  padding: 16px;
}

.profile-container {
  .profile {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}

.avatar {
  width: rem(130);
  height: rem(130);
  border-radius: 50%;
  overflow: hidden;
  background-color: gray;
  text-align: center;
  vertical-align: middle;
}

@media screen and (min-width: 376px) {
  .label-col {
    flex: 0 0 16.66667%;
    /* Based on Bootstrap's 12-column system */
    max-width: 16.66667%;
    padding-right: 15px;
    text-align: right;
  }
}

.button-container {
  display: flex;
  flex-direction: row;
  /* Default direction */
  justify-content: flex-end;
}

.program-image {
  height: 100%;
  width: 100%;
}

.program-image-wrapper {
  width: 170px;
  height: 150px;
}

/* Media query for mobile devices */
@media (max-width: 500px) {
  .button-container {
    flex-direction: column;
    /* Stack buttons vertically */
    align-items: center;
    /* Center-align buttons for better aesthetics */
  }

  .program-desc {
    width: 120px;
    margin-left: 5px;
  }

  .custom-button-yes,
  .custom-button-no {
    margin-right: 0;
    /* Remove right margin */
    margin-bottom: 8px;
    font-size: 18px !important;
    /* Add some space between buttons */
  }

  .text-right {
    text-align: center;
    /* Center text for mobile */
  }

  .program-image-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 150px;
    height: 130px;
  }

  .program-image {
    height: 100%;
    width: 100%;
  }
}
</style>
